import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Pulse from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";
import axios from "axios";

export default function BlogView() {
  const location = useLocation();
  // const imageUrl = "https://paytack.com/paytackapi/images/"+params.image;

  useEffect(() => {
    // blog = location.state.state;
  }, []);

  return (
    <>
      <section className="block relative z-1 bg-white pt-20">
        <div className="text-center">
          {/* <Pulse> */}
            <center>
              <img
                alt="..."
                className="align-middle border-none h-auto rounded-lg"
                style={{
                  objectFit: "cover",
                  width: "max-width",
                  padding: "30px",
                  // width: "500px",
                  // height: "300px"
                }}
                // src={"https://picsum.photos/seed/picsum/200"}
                src={(location.state.state.image == "")? "https://picsum.photos/seed/picsum/200": location.state.state.image}
              />
            </center>
            <br></br>
          {/* </Pulse> */}
          <Pulse>
            <center>
              <h5 className="font-semibold text-3xl">{location.state.state.title}</h5>
            </center>
            <div className="  ">
            {/* {location.state.state.body} */}
              <div className="" dangerouslySetInnerHTML={{__html: location.state.state.body}} />
            </div><br></br>
            <div>
              Posted by <b>{location.state.state.addedby}</b> on <b>{location.state.state.dateadded}.</b>
            </div>
          </Pulse>
          
        </div>
      </section>
    </>
  );
}

// export default BlogView();
