import React, { useState } from "react";
import Slide from "react-awesome-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import herobannerImage from "../../assets/img/home/art.png";
import herobannerSubImage from "../../assets/img/home/art-sub.png";
import MobileHomeTwo from '../../assets/img/mobile-home.png'
import MobileHomeOne from '../../assets/img/mobile-home-one.png'
import Group7354 from "../../assets/img/customers/Group7354.png";
import Group7455 from "../../assets/img/customers/Group-7455.png";

import h1 from "../../assets/img/h1.png";
import h2 from "../../assets/img/h2.png";
import h3 from "../../assets/img/h3.png";

import "../../styles/Simple.css";

const BusinessBanner = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: !modalIsOpen,
        autoplaySpeed: 5000,
      };

      
    return (
        <Slide top>
            <div className="simple_container px-16">
                <div className="simple_sub_container">
                    <div className="simple_container-left px-45 w-full md:w-7/12">
                        <h1 className="simple-main__title text-3xl font-semibold">All-in-one smart loyalty</h1>
                        <h1 className="simple-main__title text-3xl font-semibold">
                            solution to help grow
                        </h1>
                        <h1 className="simple-main__title text-3xl font-semibold">your business.</h1>
                        <div className="simple-paragraph">
                            Attract more customers, increase sales and build customer<br></br> 
                            loyalty. Our platform allows business to build their loyalty <br></br> 
                            programs and get listed on the app with a few clicks.
                        </div>
                    
                    
                    </div>
                    <div className="simple_container-right px-45 w-full md:w-5/12">
                        <img
                            src={require("assets/img/businessbanner.png")}
                            alt="For Business"
                            width="120%"
                            className="logo-img"
                        ></img>
                        {/* <Slider {...settings}>
                            <div className="hero-banner_container-left">
                                <img src={h1} alt="" className="phone-img" />
                                <img src={MobileHomeTwo} alt="" className="mobile-home-two"/>
                            </div>
                            <div className="hero-banner_container-left">
                                <img src={h2} alt="" className="phone-img" />
                                <img src={MobileHomeTwo} alt="" className="mobile-home-two"/>
                            </div>
                            <div className="hero-banner_container-left">
                                <img src={h3} alt="" className="phone-img" />
                                <img src={MobileHomeTwo} alt="" className="mobile-home-two"/>
                            </div>
                        </Slider> */}
                    </div> 
                </div>
          
            </div>
        </Slide>
    
    )
}

export default BusinessBanner;
