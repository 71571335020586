import React, { useState, useEffect } from "react";

// components

import HowItWorks from "../components/Home/HowItWorks";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer.js";


export default function ContactUs() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
  return (
    
    <>
            <Navbar/>
            {/* <Sidebar
                linksData={menuDataHome}
                isMenuOpen={isMenuOpen}
                menuToggle={menuToggle}
            /> */}
            <br></br><br></br>
            <HowItWorks/>
            <br></br><br></br>
            <Footer/>
    </>
  );
}