import React, { useState, useEffect } from "react";
import Pulse from "react-awesome-reveal";
import{ Fade }from "react-awesome-reveal";
import axios from "axios";
import Carousel from 'react-multi-carousel';
import { useHistory } from "react-router-dom";
import headIcon from "../../assets/img/home/how-it-works-images/logo_element.svg";

// components



export default function BlogsListHome() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const navigate = useHistory();
    const [searchInput, setSearchInput] = useState("");
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
      getBlogs();
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };
      
    if (searchInput.length > 0) {
        blogs.filter((blog) => {
        return blog.title.match(searchInput);
    });
    }
  
    function getBlogs(){
      axios.get('https://paytack.com/paytackapi/blogs/').then(function(response){
        // console.log(response.data);
        let publishedBlogs = [];
        for(var i=0; i < response.data.length; i++){
          // console.log("Blog status "+response.data[i].status);
          if (response.data[i].status === 1){
            publishedBlogs.push(response.data[i]);
          }
        }
        setBlogs(publishedBlogs);
      });
    
    }

  const clickBlog = (blog) => {
    console.log(blog);
    navigate.push('blogs/view', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: "John Doe",
        status: 1
      }
    })
  }

  return (
    
    <>
    <div className="justify-center text-center flex flex-wrap">
        <Pulse>
          <div className="container-heading">
            <img
              className="container-heading__image"
              src={headIcon}
              alt="bubble"
            />
            <h1 className="container-heading__title">
              Insights
            </h1>
          </div>
        </Pulse>
    </div>
    <Fade left>
        <div className=" bg-white">
                <Carousel 
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} 
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={6000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass={`w-full`}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style">
                  {blogs.reverse().map((blog) =>
                    <div className="px-4 mb-6 " key={blog.id}>
                    
                      {/* <Link to="/"> */}
                        <div className="bg-white mb-6 blog-carousel-image-home">
                          <img
                            alt="..."
                            className="align-middle border-none rounded-lg text-center"
                            style={{
                              objectFit: "cover",
                              width: "300px",
                              height: "300px",
                            }}
                            
                            src={(blog.image === "")? "https://picsum.photos/seed/picsum/200": blog.image}
                          />
                        </div>
                        <center>
                        <div className="">
                          <h5 className="text-xl font-semibold mt-4 pb-4" style={{width: "280px"}}>
                            {blog.title}
                          </h5>
                        </div>
                        </center>
                        <center>
                        <div className="h-10 ">
                          <div style={{width: "280px", height: "75px"}} className="line-clamp" dangerouslySetInnerHTML={{__html: blog.body}} />
                        </div>
                        </center>
                        <br></br>
                      {/* </Link> */}
                        <center>
                          <button
                              className="bgPrimaryColor blog-btn text-white text-xs font-bold uppercase px-4 py-2 rounded "
                              type="button" onClick={() => {
                                clickBlog(blog)
                              }}
                            >
                            <i className="fas fa-arrow-alt-circle-down"></i> Read more
                          </button>
                        </center>
                    </div>
                  )}
                </Carousel>
        </div>
    </Fade>
    </>
  );
}
