import React from "react";

import Pulse from "react-awesome-reveal";
import Bounce from "react-awesome-reveal";
import Slide from "react-awesome-reveal";
import Zoom from 'react-awesome-reveal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AppStore from "../../assets/img/footer-images/appstore.svg";
import PlayStore from "../../assets/img/footer-images/googleplay.svg";
import "../../styles/Shopping.css";

const Shopping = () => {
  return (
    <div className="shopping-container pt-25 px-16">
      <Pulse>
        <h3 className="text-3xl font-semibold">Taking shopping to the next level</h3>
      </Pulse>
      <Pulse>
        <p className=" pb-6 ">
          Download the free Paytack cash back app now to start earning cashback in
          your favorite locals
        </p>
      </Pulse>
      {/* <Zoom>
        <Row>
          <Col lg={3} md={0} sm={0}></Col>
          <Col  className="d-flex gap-2" sm={12} md={8} lg={3}>
            <div className="app-stores">
              <Slide left>
                <a href="/play" className="store-button">
                  <img src={PlayStore} alt="play_store" />
                  <div className="store-text">
                    <span>GET IT ON</span>
                    <span>Google Play</span>
                  </div>
                </a>  
              </Slide>
            </div>
          </Col>
          <Col sm={12} md={8} lg={3}>
            <div className="app-stores">
              <Slide right>
                <a href="/play" className="store-button">
                  <img src={AppStore} alt="app_store" />
                  <div className="store-text">
                    <span>Download on the</span>
                    <span>App Store</span>
                  </div>
                </a>
              </Slide>        
            </div>
          </Col>
        </Row> 
      </Zoom>*/}
      {/* <Zoom>
        <div className="app-stores">
          <Slide left>
            <a href="/play" className="store-button">
              <img src={PlayStore} alt="play_store" />
              <div className="store-text">
                <span>GET IT ON</span>
                <span>Google Play</span>
              </div>
            </a>  
          </Slide>
          <Slide right>
            <a href="/play" className="store-button">
              <img src={AppStore} alt="app_store" />
              <div className="store-text">
                <span>Download on the</span>
                <span>App Store</span>
              </div>
            </a>
          </Slide>        
        </div>
      </Zoom> */}
    
    </div>
  );
};

export default Shopping;