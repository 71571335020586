import React, { Component } from "react";
import Modal from "react-modal";

import "../../styles/partner.css";
import LogoElement from "../../assets/img/home/customers/logo-element-orange.svg";
import { Link } from "react-router-dom";

import Zoom from "react-awesome-reveal";
import{ Fade }from "react-awesome-reveal";

import { FaRegTimesCircle } from "react-icons/fa";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    width: "65%",
  },
};

class PartnerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  joinPayTack = (e) => {
    e.preventDefault();
  }
  
  render(){
  return (
    <>
      <Modal
        isOpen={this.props.modalIsOpen}
        //   onAfterOpen={afterOpenModal}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Partner Modal"
      >
        <section className="partner-container p-25">
          <Zoom>
            <section className="container-heading p-25 text-center">
              <img
                className="container-heading__image"
                src={LogoElement}
                alt="bubble"
              />
              <h1 className="container-heading__title p-10">
                {" "}
                BECOME A PARTNER
              </h1>
              <p className="">
                PayTack for Business gives you valuable insights based on
                real-time transaction data to target both new and loyal
                customers
              </p>
            </section>
          </Zoom>
          <div className="modal-close" onClick={this.props.closeModal}>
            <FaRegTimesCircle />
          </div>

          <div className="container-body">
            <Fade bottom>
              <div className="form-container">
                <form onSubmit={this.joinPayTack} className="contact partner-form">
                  <ul>
                    <li>
                      <i className="fas fa-building"></i>
                      <label htmlFor="businessName">
                        Business Name <span className="asterisk">*</span>
                      </label>
                      <input
                        className="inputField"
                        name="businessName"
                        type="text"
                        required
                      />
                    </li>
                    <li>
                      <i className="fas fa-building"></i>
                      <label htmlFor="cvr">
                        Company Registration num/CVR{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        className="inputField"
                        name="cvr"
                        type="text"
                        placeholder="eg: 123456-1234"
                        required
                        pattern="^\d{6}-\d{4}$"
                      />
                    </li>
                    <li>
                      <i className="fas fa-building"></i>
                      <label htmlFor="businessOwner">
                        Business Owner <span className="asterisk">*</span>
                      </label>
                      <input
                        className="inputField"
                        name="businessOwner"
                        type="text"
                        required
                      />
                    </li>
                    <li>
                      <i className="fas fa-building"></i>
                      <label htmlFor="businessAddress">
                        Business Address <span className="asterisk">*</span>
                      </label>
                      <input
                        className="inputField"
                        name="businessAddress"
                        type="text"
                        required
                      />
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <label htmlFor="email">
                        Business Email Address{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input className="inputField" name="email" type="email" required />
                    </li>
                    <li>
                      <i className="fas fa-phone"></i>
                      <label htmlFor="phone">
                        Telephone Number Of The Business{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        className="inputField"
                        name="businessPhone"
                        type="tel"
                        placeholder="8 digits with no spaces"
                        required
                        pattern="[0-9]{8}"
                      />
                    </li>
                    <li>
                      <i className="fas fa-phone"></i>
                      <label htmlFor="phone">
                        Telephone Number Of The Business Owner{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        className="inputField"
                        name="businessPhoneOwner"
                        type="tel"
                        placeholder="8 digits with no spaces"
                        required
                        pattern="[0-9]{8}"
                      />
                    </li>
                  </ul>
                  <div className="accept-conditions">
                    <input
                      type="checkbox"
                      id="acceptConditions"
                      name="acceptConditions"
                      value="businessSignUp"
                    />
                    <label htmlFor="acceptConditions">
                      By submitting the form below, you accept{" "}
                      <Link to="/termsconditions" target="_blank" onClick={this.props.closeModal}>
                        Paytack's terms of trade
                      </Link>
                    </label>
                  </div>
                  <button className=" btn-primary" type="submit" value="Send">
                    Send
                  </button>
                </form>
              </div>
            </Fade>
          </div>
        </section>
      </Modal>
    </>
  )};
};

export default PartnerModal;
