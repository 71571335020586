import React, { useState, useEffect, Component } from "react";
import Pulse from "react-awesome-reveal";
import{ Fade }from "react-awesome-reveal";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/SearchBar.css';

// components



export default function BlogsList() {
    const navigate = useHistory();
    const [searchItem, setSearchItem] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [blog, setBlog] = useState({});
    const menuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
      getBlogs();
    }, []);

    const [filteredBlogs, setFilteredBlogs] = useState(blogs);

    const handleInputChange = (e) => { 
      const searchTerm = e.target.value;
      setSearchItem(searchTerm)
  
      // filter the items using the blogs state
      let filteredItems = blogs.filter((blog) =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if(searchTerm===""){
        filteredItems=[];
      }
  
      setFilteredBlogs(filteredItems);
    }
  
    function getBlogs(){
      axios.get('https://paytack.com/paytackapi/blogs/').then(function(response){
        // console.log(response.data);
        let publishedBlogs = [];
        for(var i=0; i < response.data.length; i++){
          // console.log("Blog status "+response.data[i].status);
          if (response.data[i].status === 1){
            publishedBlogs.push(response.data[i]);
          }
        }
        setBlogs(publishedBlogs);
      });
    
    }

  const clickBlog = (blog) => {
    // console.log(blog);
    navigate.push('blogs/view', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: "John Doe",
        status: 1
      }
    })
  }

  return (
    
    <>
    <section className="z-1 bg-white pt-20">
        <Row>
          <Col>
          <center>
          <div className="text-center topBlogView  pt-20"> 
            <Pulse>
              <center>
                <img
                  alt="..."
                  className=""
                  style={{
                    width: "35px",
                  }}
                  src={require("assets/img/pay.png")}
                />
                <h1 className="font-semibold text-3xl">Our Insights.</h1>
                 <div>
                    <div className="search mb-12">
                      <input 
                        type="text" 
                        value={searchItem}
                        className="searchTerm" 
                        onChange={handleInputChange}
                        placeholder='Type to search'
                      />
                      <button type="submit" className="searchButton">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                    <ul>
                      {filteredBlogs.map(blog => 
                        <li
                        onClick={() => {
                          clickBlog(blog)
                        }}>
                            {blog.title}
                        </li>
                      )}
                    </ul>
                </div>
              </center>
            </Pulse>
            </div>
            </center>
          </Col>
        </Row>
        <Row>
            {blogs.reverse().map((blog) =>
            <Col sm={12} md={6} lg={4}>
                  <div className="text-center bg-white mb-6 ease-linear ">
                    <img
                      alt="..."
                      className=" border-none rounded-lg"
                      style={{
                        objectFit: "cover",
                        width: "300px",
                        height: "300px"
                      }}
                      
                      src={(blog.image == "")? "https://picsum.photos/seed/picsum/200": blog.image}
                    />
                  </div>
                  <div className="h-10">
                    <center>
                      <h5 className="text-xl font-semibold pb-4  text-center" style={{width: "280px"}} >
                        {blog.title}
                      </h5>
                    </center>
                  </div>
                  
                  <div className="h-10 text-center">
                    <center>
                      <div style={{width: "280px", height: "75px"}} className="line-clamp text-center"  dangerouslySetInnerHTML={{__html: blog.body}} />
                      </center>
                  </div>
                  <br></br> <br></br>
                  <center>
                    <button
                      className="mb-6 bgPrimaryColor text-white font-bold uppercase px-4 py-2 rounded shadow "
                      type="button" onClick={() => {
                        clickBlog(blog)
                      }}
                    >
                      <i className="fas fa-arrow-alt-circle-down"></i> Read more
                    </button>
                  </center>
                
              </Col>
            )}
          </Row>
        </section>
    </>
  );
}
